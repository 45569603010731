<template>
  <b-modal id="shape-height-conversion-modal" size="lg" hide-footer @hidden="onModalHidden">
    <div class="conversion-modal">
      <h3 class="conversion-title">Average Height</h3>
      <p class="text-center mb-4">
        When you change the frame shape, the PD Height needs to be updated manually
      </p>

      <div class="conversion-table">
        <div class="table-content">
          <div style="display: flex; flex-direction: column; width: 100%; align-items: end">
            <div class="headers" style="width: calc(100% - 46px) !important; gap: 6px">
              <div class="section-header" style="padding: 12px">
                <h5 style="margin-left: 25px; text-align: center">Multifocal</h5>
                <div class="sub-headers">
                  <div>
                    Original <br />
                    Shape Height
                  </div>
                  <div>
                    Capote <br />
                    Shape Height
                  </div>
                  <div>Difference in Height</div>
                </div>
              </div>
              <div class="section-header" style="padding: 12px; margin-left: 50px">
                <h5 style="margin-left: 25px; text-align: center">Bifocal</h5>
                <div class="sub-headers">
                  <div>
                    Original <br />
                    Shape Height
                  </div>
                  <div>
                    Capote <br />
                    Shape Height
                  </div>
                  <div>Difference in Height</div>
                </div>
              </div>
            </div>
          </div>

          <div class="data-rows">
            <div class="table-row" style="gap: 6px" v-for="size in sizes" :key="size.value">
              <div
                class="size-cell"
                style="gap: 10px"
                :class="[
                  getRowColorClass(size.value),
                  {
                    'selected-size': isSelectedSize(size.value),
                  },
                ]"
              >
                {{ size.text }}
              </div>
              <div class="values-section" style="gap: 4px">
                <div :class="getRowColorClass(size.value)">
                  {{ getMultifocalValue(size.value, 'original') }}
                </div>
                <div :class="getRowColorClass(size.value)">
                  {{ getMultifocalValue(size.value, 'capote') }}
                </div>
                <div :class="getRowColorClass(size.value)">
                  {{ getConversionValue(size.value, 'multifocal') }}
                </div>
              </div>
              <div class="values-section" style="gap: 4px; margin-left: 50px">
                <div :class="getRowColorClass(size.value)">
                  {{ getBifocalValue(size.value, 'original') }}
                </div>
                <div :class="getRowColorClass(size.value)">
                  {{ getBifocalValue(size.value, 'capote') }}
                </div>
                <div :class="getRowColorClass(size.value)">
                  {{ getConversionValue(size.value, 'bifocal') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center mt-4">
        <b-button
          variant="dark"
          class="close-button"
          style="width: 160px; height: 48px"
          @click="$bvModal.hide('shape-height-conversion-modal')"
        >
          Close
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ShapeHeightConversionModal',
  props: {
    selectedFrameSize: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      sizes: [
        { text: 'S', value: 'S' },
        { text: 'M', value: 'M' },
        { text: 'L', value: 'L' },
      ],
      conversionValues: {
        L: this.createSizeData(17, 21, 24, 28),
        M: this.createSizeData(16, 20, 23, 27),
        S: this.createSizeData(16, 19, 22, 26),
      },
    };
  },
  computed: {
    isSelectedSize() {
      return size => {
        if (!this.selectedFrameSize) return false;
        return this.selectedFrameSize.toUpperCase() === size;
      };
    },
  },
  methods: {
    createSizeData(bifocalOriginal, bifocalCapote, multifocalOriginal, multifocalCapote) {
      return {
        bifocal: {
          original: bifocalOriginal,
          capote: bifocalCapote,
          conversion: bifocalCapote - bifocalOriginal,
        },
        multifocal: {
          original: multifocalOriginal,
          capote: multifocalCapote,
          conversion: multifocalCapote - multifocalOriginal,
        },
      };
    },
    getBifocalValue(size, shape) {
      return this.conversionValues[size].bifocal[shape];
    },
    getMultifocalValue(size, shape) {
      return this.conversionValues[size].multifocal[shape];
    },
    getConversionValue(size, type) {
      const value = this.conversionValues[size][type].conversion;
      return `+/- ${Math.abs(value)}`;
    },
    getRowColorClass(size) {
      const colorMap = {
        L: 'row-green',
        M: 'row-yellow',
        S: 'row-blue',
      };
      return colorMap[size];
    },
    onModalHidden() {
      this.$nextTick(() => {
        if (this.$bvModal) {
          this.$bvModal.hide('shape-height-conversion-modal');
        }
      });
    },
  },
  beforeDestroy() {
    if (this.$bvModal) {
      this.$bvModal.hide('shape-height-conversion-modal');
    }
  },
};
</script>

<style scoped>
.conversion-modal {
  padding: 20px;
  background-color: #fff;
}

.conversion-table {
  background-color: #f8f9fa;
  overflow: hidden;
  margin: 0 -20px;
}

.table-content {
  padding: 20px;
}

.headers {
  display: flex;
  background-color: transparent;
  border-radius: 8px;
}

.section-header {
  background-color: #fff;
  flex: 1;
}

.section-header h5 {
  font-weight: bold;
  margin-bottom: 10px;
}

.sub-headers {
  display: flex;
  color: #666;
  gap: 4px;
}

.sub-headers > div {
  flex: 1;
  text-align: center;
}

.data-rows {
  overflow: hidden;
}

.table-row {
  display: flex;
  width: 100%;
}

.size-cell {
  width: 40px;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  transition: opacity 0.3s ease;
}

.size-cell:hover {
  opacity: 0.5;
}

.selected-size {
  outline: 2px solid black;
  outline-offset: -2px;
}

.values-section {
  flex: 1;
  display: flex;
  width: calc(50% - 20px);
}

.values-section > div {
  flex: 1;
  padding: 8px;
  text-align: center;
}

.row-green {
  background-color: rgba(21, 211, 74, 0.3);
}

.row-yellow {
  background-color: rgba(255, 168, 0, 0.3);
}

.row-blue {
  background-color: rgba(0, 174, 199, 0.3);
}

.close-button {
  border-radius: 4px;
  padding: 8px 24px;
  font-weight: 500;
}

/* Modal customization */
:deep(.modal-title) {
  font-weight: bold;
}

:deep(.modal-header) {
  border-bottom: none;
}

/* Add responsive styles */
@media (max-width: 768px) {
  .conversion-table {
    margin: 0 -10px;
  }

  .table-content {
    padding: 10px;
  }

  .values-section > div {
    padding: 4px;
    font-size: 0.9em;
  }
}

.conversion-title {
  font-family: 'Graphik', sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: -1%;
  text-align: center;
  margin-bottom: 16px;
}
</style>
<template>
  <div>
    <div v-if="pd.message">
      <ErrorMessage class="mr-2" type="box">{{ pd.message }}</ErrorMessage>
    </div>
    <div>
      <div v-if="type === 'width'">
        <div v-if="!rightWidth || !leftWidth">
          <span class="mr-2">{{ binoWidth }}</span>
        </div>
        <div v-else>
          <span class="mr-2"><span class="font-weight-medium mr-2">R</span>{{ rightWidth }}</span
          ><br /><span class="mr-2"
            ><span class="font-weight-medium mr-2">L</span>{{ leftWidth }}</span
          >
        </div>
      </div>
      <div v-else>
        <span class="mr-2"><span class="font-weight-medium mr-2">R</span>{{ rightHeight }}</span
        ><br /><span class="mr-2"
          ><span class="font-weight-medium mr-2">L</span>{{ leftHeight }}</span
        >
      </div>
      <div v-if="presenterMessage">
        <p class="warning mr-2 mt-3">{{ presenterMessage.description }}</p>
      </div>

      <div v-if="isShowSourceMessage">
        <p class="warning mr-2 mt-3">
          PD Source: <br />
          Online PD Meter
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import ErrorMessage from '@/components/ErrorMessage.vue';

export default {
  name: 'PupilDistanceDetail',
  components: {
    ErrorMessage,
  },
  props: {
    pd: Object,
    type: String,
    sourceMessage: Boolean,
  },
  computed: {
    errorText() {
      return this._.get(this.pd, 'error');
    },
    isShowSourceMessage() {
      return this.sourceMessage;
    },
    pdData() {
      return this._.get(this.pd, 'data');
    },
    presenterMessage() {
      return this._.get(this.pdData, 'message');
    },
    widthPdZone() {
      return this._.get(this.pdData, 'width.pdZone');
    },
    heightPdZone() {
      return this._.get(this.pdData, 'height.pdZone');
    },
    rightWidth() {
      const width = this._.get(this.pdData, 'width.right');
      return width ? `${width}mm @ ${this.widthPdZone || ''}` : '';
    },
    leftWidth() {
      const width = this._.get(this.pdData, 'width.left');
      return width ? `${width}mm @ ${this.widthPdZone || ''}` : '';
    },
    rightHeight() {
      const width = this._.get(this.pdData, 'height.right');
      return width ? `+${width}mm @ ${this.heightPdZone || ''}` : '';
    },
    leftHeight() {
      const width = this._.get(this.pdData, 'height.left');
      return width ? `+${width}mm @ ${this.heightPdZone || ''}` : '';
    },
    binoWidth() {
      const width = this._.get(this.pdData, 'width.binocular');
      return width ? `${width}mm @ ${this.widthPdZone || ''}` : '';
    },
  },
};
</script>

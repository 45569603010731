<template>
  <div class="step-holder" v-bind:class="{ completed: activeJobEditStep > 1 }">
    <div class="title-bar">
      <div style="width: 100%">
        <div style="display: flex; justify-content: space-between">
          <h3><span>1. Frame</span></h3>
          <div>
            <a @click="editThisStep" v-if="activeJobEditStep > 1">Change</a>
            <a class="ml-4" @click="resetThisStep" v-if="activeJobEditStep > 1">Reset</a>
          </div>
        </div>

        <div class="selected-features" v-if="activeJobEditStep > 1 && selectedFrameData.frameSize">
          <div v-if="selectedFrameData.frameSize != 'nd'"><b>Shape</b>{{ getShapeName() }}</div>
          <div>
            <b>Frame</b>{{ getSizeName(selectedFrameData.frameSize) }}
            {{
              $store.state.jobsModule.selectedFrameData.frameSize != 'nd'
                ? ' / ' + getColorName(selectedFrameData.frameColor, colors)
                : ''
            }}
          </div>
          <div>
            <b>Right Arm</b>{{ getSizeName(selectedFrameData.rightArmSize) }}
            {{
              $store.state.jobsModule.selectedFrameData.frameSize != 'nd'
                ? ' / ' + getColorName(selectedFrameData.rightArmColor, colors)
                : ''
            }}
          </div>
          <div>
            <b>Left Arm</b>{{ getSizeName(selectedFrameData.leftArmSize) }}
            {{
              $store.state.jobsModule.selectedFrameData.frameSize != 'nd'
                ? ' / ' + getColorName(selectedFrameData.leftArmColor, colors)
                : ''
            }}
          </div>
        </div>
      </div>
    </div>
    <v-wait for="Color List">
      <template slot="waiting">
        <div class="text-center mb-3">
          <b-spinner></b-spinner>
        </div>
      </template>
      <ValidationObserver v-slot="{ handleSubmit, invalid }">
        <b-form
          class="mt-4"
          @submit.stop.prevent="handleSubmit(sendFrameForm)"
          v-if="activeJobEditStep === 1"
          novalidate="novalidate"
        >
          <div class="table-title-wrapper">
            <h5>Size</h5>
          </div>
          <div class="selection-table">
            <div class="selection-item">
              <h6>Shape</h6>
              <div class="selection-holder">
                <ValidationProvider name="Frame shape" rules="required" v-slot="validationContext">
                  <b-form-group>
                    <div class="radio-buttons">
                      <label v-for="shape in frameShapes" :key="shape.value" class="radio-button">
                        <input
                          type="radio"
                          :value="shape.value"
                          v-model="frameData.frameShape"
                          :name="'frame-shape'"
                          :id="'frame-shape-' + shape.value"
                          :state="getValidationState(validationContext)"
                        />
                        <span class="radio-button-content">
                          <span class="radio-circle"></span>
                          <img :src="shape.image" :alt="shape.text" class="shape-icon" />
                          {{ shape.text }}
                        </span>
                      </label>
                    </div>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </div>
            </div>

            <div class="selection-type">
              <h6>Size</h6>
              <div class="options-holder">
                <div
                  class="single-option"
                  v-for="frameSize in frameSizeOptions"
                  :key="frameSize.text"
                >
                  <div class="option-label" @click="onSetAllSizes(frameSize)">
                    {{ frameSize.text }}
                  </div>
                </div>
              </div>
            </div>
            <div class="selection-item">
              <h6>Frame</h6>
              <div class="selection-holder">
                <ValidationProvider name="Frame size" rules="required" v-slot="validationContext">
                  <b-form-group>
                    <b-form-radio-group
                      v-model="$store.state.jobsModule.selectedFrameData.frameSize"
                      :options="frameSizeOptions"
                      :state="getValidationState(validationContext)"
                      :disabled="
                        frameData.frameShape === 'non-dresden' ||
                        (frameData.frameShape === 'capote' &&
                          $store.state.jobsModule.selectedFrameData.frameSize === 'extra-small')
                      "
                    ></b-form-radio-group>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </div>
            </div>
            <div class="selection-item">
              <h6>Right Arm</h6>
              <div class="selection-holder">
                <ValidationProvider
                  name="Right Arm size"
                  rules="required"
                  v-slot="validationContext"
                >
                  <b-form-group>
                    <b-form-radio-group
                      :checked="$store.state.jobsModule.selectedFrameData.rightArmSize"
                      @change="onChangeArmRightSize"
                      :options="frameSizeOptions"
                      :state="getValidationState(validationContext)"
                      :disabled="frameData.frameShape === 'non-dresden'"
                    ></b-form-radio-group>
                  </b-form-group>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </ValidationProvider>
              </div>
            </div>
            <div class="selection-item">
              <h6>Left Arm</h6>
              <div class="selection-holder">
                <ValidationProvider
                  name="Left Arm size"
                  rules="required"
                  v-slot="validationContext"
                >
                  <b-form-group>
                    <b-form-radio-group
                      :checked="$store.state.jobsModule.selectedFrameData.leftArmSize"
                      @change="onChangeArmLeftSize"
                      :options="frameSizeOptions"
                      :state="getValidationState(validationContext)"
                      :disabled="frameData.frameShape === 'non-dresden'"
                    ></b-form-radio-group>
                  </b-form-group>
                  <b-form-invalid-feedback>{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </ValidationProvider>
              </div>
            </div>
          </div>

          <a
            href="#"
            style="
              display: flex;
              align-items: center;
              justify-content: start;
              gap: 8px;
              color: var(--primary);
              margin-bottom: 12px;
              text-decoration: none;
            "
            @click.prevent="showShapeHeightConversion"
          >
            <svg
              width="20"
              height="12"
              viewBox="0 0 20 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.30775 11.5C1.80258 11.5 1.375 11.325 1.025 10.975C0.675 10.625 0.5 10.1963 0.5 9.689V2.311C0.5 1.80367 0.675 1.375 1.025 1.025C1.375 0.675 1.80258 0.5 2.30775 0.5H17.6923C18.1974 0.5 18.625 0.675 18.975 1.025C19.325 1.375 19.5 1.80367 19.5 2.311V9.689C19.5 10.1963 19.325 10.625 18.975 10.975C18.625 11.325 18.1974 11.5 17.6923 11.5H2.30775ZM2.30775 10H17.6923C17.7693 10 17.8398 9.96792 17.9038 9.90375C17.9679 9.83975 18 9.76925 18 9.69225V2.30775C18 2.23075 17.9679 2.16025 17.9038 2.09625C17.8398 2.03208 17.7693 2 17.6923 2H14.75V4.86525C14.75 5.07775 14.6781 5.25592 14.5343 5.39975C14.3904 5.54342 14.2122 5.61525 13.9998 5.61525C13.7871 5.61525 13.609 5.54342 13.4655 5.39975C13.3218 5.25592 13.25 5.07775 13.25 4.86525V2H10.75V4.86525C10.75 5.07775 10.6781 5.25592 10.5343 5.39975C10.3904 5.54342 10.2122 5.61525 9.99975 5.61525C9.78708 5.61525 9.609 5.54342 9.4655 5.39975C9.32183 5.25592 9.25 5.07775 9.25 4.86525V2H6.75V4.86525C6.75 5.07775 6.67808 5.25592 6.53425 5.39975C6.39042 5.54342 6.21225 5.61525 5.99975 5.61525C5.78708 5.61525 5.609 5.54342 5.4655 5.39975C5.32183 5.25592 5.25 5.07775 5.25 4.86525V2H2.30775C2.23075 2 2.16025 2.03208 2.09625 2.09625C2.03208 2.16025 2 2.23075 2 2.30775V9.69225C2 9.76925 2.03208 9.83975 2.09625 9.90375C2.16025 9.96792 2.23075 10 2.30775 10Z"
                fill="#2A68F5"
              />
            </svg>

            Shape Height Conversion
          </a>

          <div
            v-if="$store.state.jobsModule.selectedFrameData.frameSize != 'nd' && !isDresdenGoOrder"
          >
            <div class="table-title-wrapper">
              <h5>Options</h5>
            </div>
            <div class="selection-table" style="padding: 10px">
              <b-form-checkbox
                v-model="isExistingDresden"
                class="custom-checkbox-button"
                name="check-button"
                style="margin-top: 8px"
                @change="onChangeIsExistingDresden"
              >
                Existing Dresden
              </b-form-checkbox>
            </div>
          </div>

          <div v-if="$store.state.jobsModule.selectedFrameData.frameSize != 'nd'">
            <div class="table-title-wrapper">
              <h5>Colour</h5>
            </div>
            <div class="selection-table">
              <div class="selection-type">
                <h6>Colour</h6>
                <h5>Standard</h5>
                <div class="options-holder">
                  <div class="single-option" v-for="color in standardColorOptions" :key="color.key">
                    <div class="option-label" @click="setAllcolour(color, 'normal')">
                      <span class="color" v-bind:style="{ backgroundColor: color.hex_code }"></span
                      >{{ color.name }}
                    </div>
                  </div>
                </div>
                <h5>Special</h5>
                <div class="options-holder">
                  <div class="single-option" v-for="color in specialColorOptions" :key="color.key">
                    <div class="option-label" @click="setAllcolour(color, 'special')">
                      <span class="color" v-bind:style="{ backgroundColor: color.hex_code }"></span
                      >{{ color.name }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="selection-item">
                <h6>Frame</h6>
                <div class="selection-holder with-title">
                  <ValidationProvider
                    name="Frame color"
                    rules="required"
                    v-slot="validationContext"
                  >
                    <b-form-group>
                      <b-form-radio-group
                        v-model="$store.state.jobsModule.selectedFrameData.frameColor"
                        :options="standardColorOptions"
                        @change="onChangeFrameColor"
                        value-field="id"
                        text-field="name"
                        :state="getValidationState(validationContext)"
                      ></b-form-radio-group>
                    </b-form-group>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </ValidationProvider>
                  <ValidationProvider
                    name="Frame color"
                    rules="required"
                    v-slot="validationContext"
                  >
                    <b-form-group class="title-spacer">
                      <b-form-radio-group
                        v-model="$store.state.jobsModule.selectedFrameData.frameColor"
                        :options="specialColorOptions"
                        value-field="id"
                        text-field="name"
                        :state="getValidationState(validationContext)"
                      ></b-form-radio-group>
                    </b-form-group>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </div>
              </div>
              <div class="selection-item">
                <h6>Right Arm</h6>
                <div class="selection-holder with-title">
                  <ValidationProvider name="Arm color" rules="required" v-slot="validationContext">
                    <b-form-group>
                      <b-form-radio-group
                        :checked="$store.state.jobsModule.selectedFrameData.rightArmColor"
                        @change="onChangeArmRightColor"
                        :options="armColorOptions"
                        value-field="id"
                        text-field="name"
                        :state="getValidationState(validationContext)"
                      ></b-form-radio-group>
                    </b-form-group>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </ValidationProvider>
                  <ValidationProvider name="Arm color" rules="required" v-slot="validationContext">
                    <b-form-group class="title-spacer">
                      <b-form-radio-group
                        :checked="$store.state.jobsModule.selectedFrameData.rightArmColor"
                        @change="onChangeArmRightColor"
                        :options="armColorSpecialOptions"
                        value-field="id"
                        text-field="name"
                        :state="getValidationState(validationContext)"
                      ></b-form-radio-group>
                    </b-form-group>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </div>
              </div>

              <div class="selection-item">
                <h6>Left Arm</h6>
                <div class="selection-holder with-title">
                  <ValidationProvider name="Arm color" rules="required" v-slot="validationContext">
                    <b-form-group>
                      <b-form-radio-group
                        :checked="$store.state.jobsModule.selectedFrameData.leftArmColor"
                        @change="onChangeArmLeftColor"
                        :options="armColorOptions"
                        value-field="id"
                        text-field="name"
                        :state="getValidationState(validationContext)"
                      ></b-form-radio-group>
                    </b-form-group>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </ValidationProvider>
                  <ValidationProvider name="Arm color" rules="required" v-slot="validationContext">
                    <b-form-group class="title-spacer">
                      <b-form-radio-group
                        :checked="$store.state.jobsModule.selectedFrameData.leftArmColor"
                        @change="onChangeArmLeftColor"
                        :options="armColorSpecialOptions"
                        value-field="id"
                        text-field="name"
                        :state="getValidationState(validationContext)"
                      ></b-form-radio-group>
                    </b-form-group>
                    <b-form-invalid-feedback>{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <div class="confirmation-wrapper" ref="confirmationWrapper">
            <b-button class="mr-3" variant="outline-secondary" @click="cancel()" type="button"
              >Cancel</b-button
            >
            <b-button class="button-with-loader" type="submit" :disabled="invalid" variant="primary"
              >Confirm</b-button
            >
          </div>
        </b-form>
      </ValidationObserver>
    </v-wait>

    <ChangePdHeightModal
      ref="pdHeightModal"
      :old-shape="pdModalData.oldShape"
      :new-shape="pdModalData.newShape"
      :old-size="pdModalData.oldSize"
      :new-size="pdModalData.newSize"
      :lens-type="pdModalData.lensType"
      :is-on-page-edit="false"
    />
    <ShapeHeightConversionModal :selected-frame-size="frameData.frameSize" />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ChangePdHeightModal from '@/components/ChangePdHeightModal.vue';
import ShapeHeightConversionModal from '@/components/ShapeHeightConversionModal.vue';
import colorsApi from '@/api/colors';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { SIZES } from '@/support/constants';

export default {
  name: 'Frame',
  components: {
    ValidationProvider,
    ValidationObserver,
    ChangePdHeightModal,
    ShapeHeightConversionModal,
  },
  data() {
    return {
      frameData: {
        frameShape: null,
        frameSize: null,
        leftArmSize: null,
        rightArmSize: null,
        frameColor: null,
        leftArmColor: null,
        rightArmColor: null,
      },
      showSelectedColors: false,
      isExistingDresden: false,
      firstRender: true,
      frameShapes: [
        {
          value: 'original',
          text: 'Original',
          image: 'https://w.dresden.vision/images/icons/shape_classic.png',
        },
        {
          value: 'capote',
          text: 'Capote',
          image: 'https://w.dresden.vision/images/icons/shape_round.png',
        },
        {
          value: 'non-dresden',
          text: 'Non-Dresden',
          image: '/images/non-dresden.svg',
        },
      ],
      pdModalData: {
        oldShape: null,
        newShape: null,
        oldSize: null,
        newSize: null,
        lensType: null,
      },
    };
  },
  props: {
    customer: Object,
  },
  computed: {
    ...mapState({
      job: state => state.jobsModule.selectedJobData,
      activeJobEditStep: state => state.jobsModule.activeJobEditStep,
      selectedFrameData: state => state.jobsModule.selectedFrameData,
      defaultFrameData: state => state.jobsModule.defaultFrameData,
      colors: state => state.jobsModule.colors,
    }),
    ...mapGetters('jobsModule', ['isPrescriptionRequired']),
    frameSizeOptions() {
      const sizes = SIZES.filter(size => size.value !== 'nd');
      return sizes.map(size => ({
        ...size,
        disabled: size.value === 'extra-small' && this.frameData.frameShape === 'capote',
      }));
    },
    isDv4Order() {
      return this._.get(this.job, 'order.channel') === 'dv4';
    },
    isDresdenGoOrder() {
      const dresdenGoTypes = ['dg-reading-glass', 'dresdengo'];
      return dresdenGoTypes.includes(this._.get(this.job, 'item.region_product.product.type.key'));
    },
    frameColors() {
      // eslint-disable-next-line func-names
      return this._.filter(this.colors, function (o) {
        return o.type === 'Frame' && o.visible_online === 1 && o.name !== 'Existing Dresden';
      });
    },
    armColors() {
      // eslint-disable-next-line func-names
      return this._.filter(this.colors, function (o) {
        return o.type === 'Temple' && o.visible_online === 1 && o.name !== 'Existing Dresden';
      });
    },
    standardColorOptions() {
      const options = this._.filter(this.frameColors, { special: 0 });
      return this._.sortBy(
        options
          .filter(o => o.name !== 'Existing Dresden')
          .map(color => ({
            ...color,
            key: `${color.id}-${color.hex_code}`,
          })),
        ['name'],
      );
    },
    standardColorExistingOptions() {
      const options = this._.filter(this.frameColors, { special: 0 });
      return this._.sortBy(
        options.filter(o => o.name === 'Existing Dresden'),
        ['name'],
      );
    },
    specialColorOptions() {
      const options = this._.filter(this.frameColors, { special: 1 });
      return this._.sortBy(
        options.map(color => ({
          ...color,
          key: `${color.id}-${color.hex_code}`,
        })),
        ['name'],
      );
    },
    armColorOptions() {
      const options = this._.filter(this.armColors, { special: 0 });
      return this._.sortBy(
        options.filter(o => o.name !== 'Existing Dresden'),
        ['name'],
      );
    },
    armColorExistingOptions() {
      const options = this._.filter(this.armColors, { special: 0 });
      return this._.sortBy(
        options.filter(o => o.name === 'Existing Dresden'),
        ['name'],
      );
    },
    armColorSpecialOptions() {
      const options = this._.filter(this.armColors, { special: 1 });
      return this._.sortBy(options, ['name']);
    },
    availableFrameShapes() {
      // Filter out non-dresden option if frameSize is not 'nd'
      return this.frameShapes;
    },
    availableSizeOptions() {
      // Filter out 'nd' option if shape is not 'non-dresden'
      if (this.$store.state.jobsModule.selectedFrameData.shape === 'non-dresden') {
        return SIZES.filter(size => size.value === 'nd');
      }
      return SIZES.filter(size => size.value !== 'nd');
    },
  },
  mounted() {
    const isExistingDresden = this._.get(this.job, 'frame.existingdresden');
    if (isExistingDresden !== null && typeof isExistingDresden !== 'undefined') {
      this.isExistingDresden = isExistingDresden;
      this.setIsExistingDresden(isExistingDresden);
    } else {
      const isOldExistingDresden = this._.get(this.job, 'frame.color.name') === 'Existing Dresden';
      if (isOldExistingDresden) {
        this.isExistingDresden = true;
        this.setIsExistingDresden(true);
        setTimeout(() => {
          const frameColors = this._.filter(this.frameColors, { special: 0 });
          const frame = frameColors.filter(color => color.name === 'Black')[0];
          this.setFrameColor(frame.id);
          const arm = this.armColorOptions.filter(armc => armc.name === 'Black')[0];
          this.setArmLeftColor(arm.id);
          this.setArmRightColor(arm.id);
        }, 1000);
        this.setIsExistingDresden(true);
      } else {
        this.isExistingDresden = false;
        this.setIsExistingDresden(false);
      }
    }

    // Add event listener for PD height modal
    // this.$root.$on('show-pd-change-height-modal', this.showPdHeightModal);
    // this.$root.$on('update-pd-heights', this.showPdHeightModal);
  },
  watch: {
    'frameData.frameColor': 'scrollToConfirmation',
    'frameData.leftArmColor': 'scrollToConfirmation',
    'frameData.rightArmColor': 'scrollToConfirmation',
    selectedFrameData: {
      deep: true,
      immediate: true,
      handler(newData) {
        if (this.frameData.frameShape !== null) return;
        if (!newData.shape) return;

        this.frameData.frameShape = newData.shape.toLowerCase();
      },
    },
    'frameData.frameShape': {
      handler(newShape, oldShape) {
        // Skip for non-dresden shapes
        if (newShape === 'non-dresden') {
          this.$store.state.jobsModule.selectedFrameData.frameSize = 'nd';
          this.$store.state.jobsModule.selectedFrameData.leftArmSize = 'nd';
          this.$store.state.jobsModule.selectedFrameData.rightArmSize = 'nd';
          this.frameData.frameSize = 'nd';
          this.frameData.frameShape = newShape.toLowerCase();
          this.$store.state.jobsModule.selectedFrameData.shape = newShape.toLowerCase();
          return;
        }

        // Auto-adjust size when switching to capote shape
        if (newShape === 'capote') {
          const currentSize = this.$store.state.jobsModule.selectedFrameData.frameSize;
          if (currentSize === 'extra-small') {
            // Change size to 'S' for frame and arms
            this.$store.state.jobsModule.selectedFrameData.frameSize = 's';
            this.$store.state.jobsModule.selectedFrameData.leftArmSize = 's';
            this.$store.state.jobsModule.selectedFrameData.rightArmSize = 's';
          }
        }

        // Map display shapes to database shapes
        const shapeMapping = {
          original: 'classic',
          capote: 'round',
        };

        // Show PD height modal if shape changed and PD exists
        if (
          newShape !== oldShape &&
          this.$store.state.jobsModule.selectedPd?.detail &&
          this.$store.state.jobsModule.selectedPd?.detail.id
        ) {
          const pdData = {
            ...this.$store.state.jobsModule.selectedPd.detail,
            glass_shape_type: shapeMapping[newShape] || newShape,
          };

          // Emit event to show PD height modal
          this.$root.$emit('show-pd-change-height-modal', {
            pd: pdData,
            oldShape: shapeMapping[oldShape] || oldShape,
            newShape: shapeMapping[newShape] || newShape,
          });
        }

        this.frameData.frameShape = newShape.toLowerCase();
        this.$store.state.jobsModule.selectedFrameData.shape = newShape.toLowerCase();
      },
    },
    'frameData.frameSize': {
      handler(newSize, oldSize) {
        if (!oldSize || newSize === 'nd') {
          return;
        }

        // Show PD height modal if size changed and PD exists
        if (
          newSize !== oldSize &&
          this.$store.state.jobsModule.selectedPd?.detail &&
          this.$store.state.jobsModule.selectedPd?.detail.id
        ) {
          const pdData = {
            ...this.$store.state.jobsModule.selectedPd.detail,
          };

          this.$root.$emit('update-pd-heights', {
            pd: pdData,
            oldSize,
            newSize,
          });
        }
      },
    },
  },
  methods: {
    ...mapMutations('jobsModule', {
      setArmSize: 'SET_ARM_SIZE',
      setArmRightSize: 'SET_ARM_RIGHT_SIZE',
      setArmLeftSize: 'SET_ARM_LEFT_SIZE',
      setArmColor: 'SET_ARM_COLOR',
      setArmRightColor: 'SET_ARM_RIGHT_COLOR',
      setArmLeftColor: 'SET_ARM_LEFT_COLOR',
      setIsExistingDresden: 'SET_IS_EXISTING_DRESDEN',

      setAllSizes: 'SET_ALL_SIZES',
      setAllColors: 'SET_ALL_COLORS',
      setActiveJobStep: 'SET_ACTIVE_JOB_EDIT_STEP',
      setFrameColor: 'SET_FRAME_COLOR',
    }),
    ...mapActions('jobsModule', {
      resetAllSizes: 'resetAllSizes',
      resetAllColors: 'resetAllColors',
      resetSizesAndColors: 'resetSizesAndColors',
    }),
    sendFrameForm() {
      if (this.customer) {
        this.setActiveJobEditStep(2);
      } else {
        this.setActiveJobEditStep(5);
      }
    },
    getColors() {
      this.$wait.start('Color List');
      colorsApi.getAllColors().then(({ data }) => {
        const frameColors = this._.filter(data.data, { type: 'Frame', visible_online: 1 });
        const standardFrameColors = this._.filter(frameColors, { special: 0 });
        const specialFrameColors = this._.filter(frameColors, { special: 1 });
        this.colors = frameColors;
        this.standardColorOptions = this._.sortBy(standardFrameColors, ['name']);
        this.specialColorOptions = this._.sortBy(specialFrameColors, ['name']);
        this.$wait.end('Color List');
        this.showSelectedColors = true;
      });
    },
    setAllcolour(colour, type = 'normal') {
      const frame = colour;
      if (type === 'normal') {
        const arm = this.armColorOptions.filter(armc => armc.name === colour.name)[0];
        this.setArmColor(arm.id);
      } else if (type === 'special') {
        const arm = this.armColorSpecialOptions.filter(armc => armc.name === colour.name)[0];
        this.setArmColor(arm.id);
      } else if (type === 'existing') {
        const arm = this.armColorExistingOptions.filter(armc => armc.name === colour.name)[0];
        this.setArmColor(arm.id);
      }
      this.setFrameColor(frame.id);
      this.$refs.confirmationWrapper.scrollIntoView({ behavior: 'smooth' });
    },
    onSetAllSizes(size) {
      if (this.frameData.frameShape === 'non-dresden') return;
      if (this.frameData.frameShape === 'capote' && size.value === 'extra-small') return;

      this.setAllSizes(size.value);
      if (size.value === 'nd') {
        this.onChangeIsExistingDresden(false);
        this.isExistingDresden = false;
      }
    },
    editThisStep() {
      this.setActiveJobStep(1);
    },
    resetThisStep() {
      this.setActiveJobStep(1);
      this.resetSizesAndColors();
    },
    onChangeArmRightSize(val) {
      this.setArmRightSize(val);
    },
    onChangeArmLeftSize(val) {
      this.setArmLeftSize(val);
    },
    onChangeArmColor(val) {
      this.setArmColor(val);
      this.frameData.leftArmColor = val;
      this.frameData.rightArmColor = val;
    },
    onChangeArmRightColor(val) {
      this.setArmRightColor(val);
      this.frameData.rightArmColor = val;
    },
    onChangeArmLeftColor(val) {
      this.setArmLeftColor(val);
      this.frameData.leftArmColor = val;
    },
    onChangeFrameColor(val) {
      //this.setFrameColor(val);
      this.frameData.frameColor = val;
    },
    onChangeIsExistingDresden(val) {
      this.setIsExistingDresden(val);
    },
    cancel() {
      this.resetSizesAndColors();
      this.setActiveJobEditStep(5);
    },
    scrollToConfirmation() {
      if (
        this.frameData.frameColor !== null &&
        this.frameData.leftArmColor !== null &&
        this.frameData.rightArmColor !== null
      ) {
        this.$refs.confirmationWrapper.scrollIntoView({ behavior: 'smooth' });
      }
    },
    getShapeName() {
      const frameSize = this.$store.state.jobsModule.selectedFrameData.frameSize;
      const shape = this.$store.state.jobsModule.selectedFrameData.shape;
      if (frameSize === 'nd') {
        return 'Non-Dresden';
      }

      if (!shape) {
        return '';
      }

      return shape.charAt(0).toUpperCase() + shape.slice(1);
    },
    getSizeName(size) {
      if (!size) return '';
      const sizeOption = SIZES.find(s => s.value === size);
      return sizeOption ? sizeOption.text : '';
    },
    getColorName(colorId, colors) {
      if (!colorId || !colors) return '';
      const color = colors.find(c => c.id === colorId);
      return color ? color.name : '';
    },
    showPdHeightModal(data) {
      this.pdModalData = {
        oldShape: data.oldShape,
        newShape: data.newShape,
        oldSize: data.oldSize,
        newSize: data.newSize,
        lensType: this.job?.lens_type || null,
      };
      this.$bvModal.show('pd-change-height-modal');
    },
    showShapeHeightConversion() {
      this.$bvModal.show('shape-height-conversion-modal');
    },
  },
  beforeDestroy() {
    // Clean up event listeners
    this.$root.$off('show-pd-change-height-modal', this.showPdHeightModal);
    this.$root.$off('update-pd-heights', this.showPdHeightModal);
  },
};
</script>

<style lang="scss" scoped>
.radio-buttons {
  display: flex;
  flex-direction: column;
  width: 275px;
  gap: 10px;
}

.radio-button {
  flex: 1;
  min-width: 80px;
  position: relative;
  cursor: pointer;
}

.radio-button input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-button-content {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.radio-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid #dedede;
  margin-right: 10px;
  display: inline-block;
  transition: all 0.3s ease;
}

.radio-button input:checked + .radio-button-content .radio-circle {
  background-color: #007bff;
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #007bff;
}

.shape-icon {
  width: 70px;
  height: 24px;
  margin-right: 8px;
}

.radio-button:hover .radio-button-content {
  border-color: #007bff;
}

.shape-height-link {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  color: var(--primary);
  font-size: 14px;
  margin-top: 12px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  i {
    font-size: 16px;
  }
}
</style>
